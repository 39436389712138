@font-face {
  font-family: "Druk Wide Bold";
  src: url("../../fonts/DrukWideBold.woff") format("woff");
  font-weight: 900;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 90px;
  width: 100%;
  backdrop-filter: blur(50px);
  position: fixed;
  top: 0;
  z-index: 999;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 30%,
    rgba(0, 212, 255, 0) 100%
  );

  &_logo {
    display: flex;
    text-decoration: none;
    gap: 10px;

    img {
      width: 30px;
    }

    h1 {
      color: white;
      font-weight: bold;
      font-family: "Druk Wide Bold";
      font-size: 20px;
      display: flex;
      align-items: center;

      @media (max-width: 690px) {
        color: black;
      }
    }
  }

  &_menuButton {
    display: none;
    z-index: 999999;

    @media (max-width: 1300px) {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 10px 15px;
      background: #fff;
      font-size: 18px;
      font-weight: 800;
      line-height: 23.4px;
      letter-spacing: 0.05em;
      border-radius: 6px;
      border: none;
    }

    span {
      font-size: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      font-weight: 300;
    }

    img {
      height: 20px;
    }

    @media (max-width: 690px) {
      padding: 0;
      background: none;

      p {
        display: none;
      }

      span {
        color: #ff3c00;
        font-size: 68px !important;
      }
    }
  }

  &_links_wrapper {
    display: flex;
    gap: 50px;

    section {
      display: flex;
      align-items: center;
      gap: 50px;

      > :first-child {
        display: none;
      }

      @media (max-width: 1300px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      gap: 15px;
    }
    @media (max-width: 690px) {
      width: 24px;
    }
  }

  &_navLink {
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: 0.05em;
    color: #fff;
    text-decoration: none;
    padding-bottom: 5px;
  }

  &_navLink[aria-current="page"] {
    border-bottom: 3px solid #ff3c00 !important;
    padding-bottom: 2px;
  }

  &_lastLink {
    margin-left: 50px;
    padding: 15px 25px;
    background: #ff3c00;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: 0.05em;
    color: #fff;
    text-decoration: none;
    border: 0;

    @media (max-width: 1440px) {
      margin-left: 0;
    }

    @media (max-width: 1300px) {
      padding: 10px 18px;
    }

    @media (max-width: 690px) {
      padding: 10px 18px;
      display: none;
    }
  }

  @media (max-width: 1440px) {
    padding: 40px;
  }

  @media (max-width: 690px) {
    padding: 20px;
    background: #fff;
  }

  &_menu_bg {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000080;
    z-index: 999;
  }

  &_menuDisabled {
    display: none;
  }

  &_menu_active {
    z-index: 9999999 !important;
    right: 40px;
    width: 386px;
    padding: 40px;
    top: 100px;
    gap: 30px !important;
    display: flex !important;
    align-items: flex-start !important;
    flex-direction: column;
    background: #ff3c00;
    position: absolute;
    border-radius: 12px;

    .header_navLink {
      display: flex;
      justify-content: flex-start;
      font-size: 30px;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0.05em;
      text-align: left;
    }

    @media (max-width: 690px) {
      right: 20px;
      top: 60px;
      max-width: 340px;
      width: calc(100% - 40px);
      gap: 20px !important;
      padding: 40px !important;
    }
  }
}
