@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overscroll-behavior: none;
  font-family: "Onest";
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.swprpgntn {
  display: block;
  width: 30px;
  height: 8px;
  border-radius: 10px;
  background: #8f8f8f;
}

.swprpgntn_active {
  background: #ff3c00;
}
