.contacts {
  padding: 90px;
  padding-top: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 70px;

  &_left {
    display: flex;
    flex-direction: column;
    gap: 40px;

    > h1 {
      margin-bottom: 30px;
    }

    a {
      color: #8f8f8f;
      text-decoration: none;
    }

    h4 {
      color: #8f8f8f;
    }

    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      gap: 20px;
      position: relative;

      > h1 {
        grid-column: 1/3;
      }

      .contacts_socials {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    @media (max-width: 690px) {
      display: flex;
      gap: 20px;

      .contacts_socials {
        position: static;
      }
    }
  }

  &_socials {
    display: flex;
    gap: 20px;
  }

  &_form {
    display: flex;
    flex-direction: column;
    max-width: 843px;
    width: 50%;
    padding: 60px;
    gap: 20px;
    border: 1px solid #8f8f8f;
    border-radius: 5px;

    input,
    textarea {
      padding: 15px 20px;
      border: 1px solid #8f8f8f;
      border-radius: 5px;
      outline: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      color: #000;
      resize: vertical;
    }

    input::placeholder,
    textarea::placeholder {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      color: #8f8f8f;
    }

    input[type="checkbox"] {
      width: 13px !important;
      height: 13px !important;
    }

    section {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      > :nth-child(1) {
        width: calc(50% - 10px);
      }

      label {
        width: calc(50% - 10px);
        position: relative;

        input[type="phone"] {
          padding-left: 40px;
          width: 100%;
        }
      }

      label:hover input[type="checkbox"]::after {
        content: "";
        position: absolute;
        width: calc(13px - 4px);
        height: calc(13px - 4px);
        border-radius: 2px;
        border: 2px solid #ff3c00;
      }

      input[type="checkbox"]:checked:after {
        content: "\2713" !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        position: absolute;
        width: calc(13px - 4px);
        height: calc(13px - 4px);
        border-radius: 2px;
        border: 2px solid #ff3c00;
        background: #ff3c00;
      }

      @media (max-width: 690px) {
        flex-direction: column;
        input[type="text"] {
          width: 100% !important;
        }
        label {
          width: 100% !important;
        }
        p {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    &_placeholder {
      position: absolute;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      top: 16.4px;
      left: 20px;
      color: #8f8f8f;
    }

    &_button {
      margin-left: auto;
      background: none;
      border: none;
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      cursor: pointer;

      input {
        display: none;
      }
    }

    &_confirm {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      max-width: 355px;
      width: 100% !important;
      
      input {
        margin-top: 2px;
      }
    }

    @media (max-width: 690px) {
      padding: 20px;
    }
  }

  &_h1 {
    font-size: 80px;
    font-weight: 500;
    line-height: 96px;
    letter-spacing: 0.03em;
    z-index: 2;

    @media (max-width: 1800px) {
      font-size: 58px;
      line-height: calc(96 / 80);
    }

    @media (max-width: 1300px) {
      font-size: 36px;
      line-height: calc(96 / 80);
    }
  }

  &_l1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 52px;
    z-index: 2;

    color: #8f8f8f;

    @media (max-width: 1800px) {
      font-size: 32px;
      line-height: calc(52 / 40);
    }

    @media (max-width: 1300px) {
      font-size: 26px;
    }
  }

  &_l2 {
    font-size: 36px;
    font-weight: 300;
    line-height: 46.8px;
    color: #8f8f8f;

    @media (max-width: 1300px) {
      font-size: 24px;
      line-height: 31px;
    }

    @media (max-width: 690px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &_h2 {
    font-size: 52px;
    font-weight: 500;
    line-height: 62.4px;

    @media (max-width: 1300px) {
      font-size: 32px;
      line-height: 38px;
    }

    @media (max-width: 690px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  &_h3 {
    font-size: 36px;
    font-weight: 500;
    line-height: 43.2px;

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 30.8px;
    }

    @media (max-width: 690px) {
      font-size: 26px;
      line-height: 31.2px;
    }
  }

  &_h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;

    @media (max-width: 1300px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &_body {
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    color: #8f8f8f;

    @media (max-width: 1300px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &_button {
    padding: 18px 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: 0.05em;
    background: #ff3c00;
    border: none;
    border-radius: 6px;
    color: #fff;
    width: fit-content;
    height: fit-content;
  }

  @media (max-width: 1440px) {
    padding: 35px;
    padding-top: 150px;
    gap: 40px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    &_form {
      width: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 690px) {
    padding: 20px;
    padding-top: 100px;
  }
}
