.modal {
    background: white;
    padding: 30px 40px;
    border-radius: 10px;
    max-width: 500px;
    min-width: 500px;
    margin: auto;
    z-index: 1001;
    @media (max-width: 690px) {
        max-width: 100%;
        min-width: 100%;
      }
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modalFooter {
    display: flex;
    justify-content: space-between;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .home_h3 {
    font-size: 36px;
    font-weight: 500;
    line-height: 43.2px;
    margin-bottom: 20px;
  }
  
  .home_h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    margin-bottom: 20px;
  }
  
  .home_projects_form_label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 100px;
    padding: 12px;
    cursor: pointer;
  }
  
  .home_projects_form_label_input {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    appearance: none;
    border-radius: 50%;
    border: 2px solid #ccc;
    position: relative;
  }
  
  .home_projects_form_label_input:checked {
    background: #ff3c00;
    border: 2px solid #ff3c00;
  }
  
  .home_projects_form_label_input:checked::after {
    content: "\2713";
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .home_projects_form_label_text {
    font-size: 18px;
    font-weight: 400;
    line-height: 19.8px;
  }
  
  .home_button {
    padding: 18px 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: 0.05em;
    background: #ff3c00;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
  }
  
  .home_button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .input, .textarea {
    padding: 15px 20px;
    border: 1px solid #8f8f8f;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    color: #000;
    width: 100%;
    margin-bottom: 10px;
    resize: vertical;
  }
  
  .textarea {
    height: 100px;
  }
  
  .budgetDeadlineContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .budgetDeadlineContainer > div {
    width: 48%;
  }
  
  .home_projects_form_confirmLabel {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .home_projects_form_confirmLabel input {
    margin-right: 10px;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .errorInput {
    border: 1px solid red;
  }
  
  .inputWrapper {
    position: relative;
  }
  
  .phoneLabel {
    position: relative;
    width: calc(50% - 10px);
  }
  
  
  .contacts_form_placeholder {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    top: 0;
    left: 20px;
    color: #8f8f8f;
  }
  
