.home {
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }

  &_intro {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 90px;
    color: #fff;
    position: relative;
    gap: 70px;
    padding-top: 90px !important;
    background: #b9b9b9;

    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &_bg::-webkit-media-controls {
      display: none !important;
    }

    &_bg::-webkit-media-controls-panel {
      display: none !important;
      -webkit-appearance: none;
      opacity: 0;
      visibility: hidden;
    }
    &_bg::-webkit-media-controls-play-button {
      display: none !important;
      -webkit-appearance: none;
      opacity: 0;
      visibility: hidden;
    }
    &_bg::-webkit-media-controls-start-playback-button {
      display: none !important;
      -webkit-appearance: none;
      opacity: 0;
      visibility: hidden;
    }

    .home_l1 {
      color: #f0f0f0;
      max-width: 50%;
    }

    .home_h1 {
      max-width: 50%;
    }

    @media (max-width: 1440px) {
      padding: 40px;
      gap: 40px;
    }

    @media (max-width: 690px) {
      padding: 20px;
      justify-content: flex-end;

      .home_l1 {
        max-width: 100%;
      }

      .home_h1 {
        max-width: 100%;
      }
    }
  }

  &_directions {
    display: flex;
    flex-direction: column;
    gap: 70px;
    padding: 150px 90px;
    position: relative;

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 690px) {
        button {
          position: absolute;
          bottom: 0;
          left: 10px;
        }
      }
    }

    &_wrapper {
      display: grid;
      grid-template-columns: repeat(3, calc(100% / 3));

      @media (max-width: 1440px) {
        grid-template-columns: repeat(2, 50%);
      }

      @media (max-width: 690px) {
        grid-template-columns: repeat(1, 100%);
      }

      > :first-child {
        .home_directions_item_topLine {
          height: 1.5px !important;
        }
      }
      > :last-child {
        .home_directions_item_bottomLine {
          height: 1.5px !important;
        }
      }

      .home_directions_item_bottomLine::before {
        @media (max-width: 690px) {
          content: "";
          position: absolute;
          height: 1px;
          left: -42px;
          transform: translateX(-100%);
          bottom: 0;
          width: 49px;
          background: #dadada;
        }
      }
    }

    &_item {
      display: flex;
      flex-direction: column;
      padding: 50px 60px;
      position: relative;
      gap: 24px;
      transition: all 0.3s ease;

      &_top {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: 1300px) {
          flex-direction: row;
          align-items: center;
        }

        @media (max-width: 690px) {
          position: relative;
          padding-left: 20px;
          gap: 50px;

          h3 {
            max-width: 210px;
            height: 62px;
            display: flex;
            align-items: center;
            padding-left: 20px;
          }

          h3::before {
            content: "";
            position: absolute;
            height: 44px;
            left: 80px;
            width: 1.5px;
            background: #dadada;
          }
        }
      }

      &_img {
        width: fit-content;
      }

      > span {
        position: absolute;
        background: #dadada;
      }

      &_topLine {
        width: calc(100% - 40px);
        height: 1px;
        top: 0;
        left: 20px;
        display: block;

        @media (max-width: 690px) {
          width: calc(100% - 133px) !important;
          left: 113px;
        }
      }

      &_rightLine {
        height: calc(100% - 40px);
        width: 1px;
        top: 20px;
        right: 0;
        display: block;

        @media (max-width: 690px) {
          width: 1.5px;
          right: 1px;
        }
      }

      &_bottomLine {
        width: calc(100% - 40px);
        height: 1px;
        bottom: 0;
        left: 20px;
        display: block;

        @media (max-width: 690px) {
          width: calc(100% - 133px) !important;
          left: 113px;
          bottom: 100%;
          transform: translateY(calc(100% + 83px));
        }
      }

      &_leftLine {
        height: calc(100% - 40px);
        width: 1px;
        top: 20px;
        left: 0;
        display: block;

        @media (max-width: 690px) {
          width: 1.5px;
        }
      }

      &_openButton {
        display: none;
        width: 20px;
        transition: all 0.3s ease;

        @media (max-width: 690px) {
          display: flex;
          position: absolute;
          right: 0;
          bottom: 0;
        }

        @media (max-width: 475px) {
          width: 13px;
          display: flex;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      &_openButton[opened="true"] {
        @media (max-width: 690px) {
          transform: rotate(45deg);
        }
      }

      @media (max-width: 1440px) {
        padding: 40px;
      }

      @media (max-width: 690px) {
        padding: 10px;
        height: 84px;

        .home_body {
          opacity: 0;
          transition: all 0.3s ease;
        }

        .home_directions_item_topLine::after {
          content: "";
          position: absolute;
          height: 1px;
          opacity: 1 !important;
          left: -40px;
          transform: translateX(-100%);
          top: 0;
          width: 49px;
          background: #dadada;
        }
      }
    }
    @media (max-width: 690px) {
      &_item[opened="true"] {
        height: 200px;

        .home_body {
          opacity: 1;
        }

        &::after {
          content: "";
          width: calc(100% - 40px);
          height: 1px;
          position: absolute;
          bottom: -1px;
          background: #dadada;
        }

        @media (max-width: 597px) {
          height: 210px;
        }

        @media (max-width: 498px) {
          height: 236px;
        }

        @media (max-width: 422px) {
          height: 262px;
        }

        @media (max-width: 389px) {
          height: 280px;
        }
      }
    }

    @media (max-width: 1440px) {
      padding: 100px 40px;
      gap: 40px;
    }

    @media (max-width: 690px) {
      padding: 80px 20px;
    }
  }

  &_animation {
    display: flex;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 90px;

    &_left {
      max-width: 40%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 24px;
    }

    &_right {
      max-width: calc(60% - 100px);

      @media (max-width: 1440px) {
        max-width: calc(60% - 60px);
      }

      @media (max-width: 690px) {
        max-width: calc(60% - 40px);
      }
    }

    @media (max-width: 1440px) {
      padding: 40px;
      gap: 40px;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 0px;

      &_right {
        width: 100%;
        max-width: 100%;
        transform: scale(0.7);
        margin-top: -80px;
      }

      &_left {
        width: 100%;
        max-width: 100%;
      }
    }

    @media (max-width: 690px) {
      padding: 20px;
      gap: 40px;
      justify-content: flex-end;

      .home_l1 {
        max-width: 100%;
      }

      .home_h1 {
        max-width: 100%;
      }

      &_right {
        margin-top: 0;
        transform: scale(1);
      }
    }
  }

  &_projects {
    display: flex;
    flex-direction: column;
    position: relative;

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 90px;

      @media (max-width: 1440px) {
        padding: 40px;
      }

      @media (max-width: 690px) {
        button {
          display: none;
          position: absolute;
          top: calc(100vw + 90px);
          left: 20px;
        }
        padding: 20px;
      }
    }
    &_carousel {
      display: none;
    }

    &_wrapper {
      display: grid;
      grid-template-columns: repeat(3, calc(100% / 3));

      > .home_button {
        display: none;
      }

      @media (max-width: 1700px) {
        grid-template-columns: repeat(2, 50%);
      }

      @media (max-width: 690px) {
        width: 100%;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(5, calc(100% - 15px));
        position: relative;
        overflow: auto;

        > .home_button {
          display: flex;
          margin: 20px;
          position: sticky;
          left: 20px;
          bottom: 0;
        }
      }

      ::webkit-scrollbar {
        display: none;
      }

      > :nth-child(4),
      > :nth-child(5) {
        color: #000;
      }

      > :last-child {
        background: #f0f0f0;
        color: #000;
        gap: 20px;

        @media (max-width: 1440px) {
          display: none;
        }
      }
    }

    > :last-child {
      background: #f0f0f0;
      color: #000;
      display: none;

      @media (max-width: 1440px) {
        aspect-ratio: auto !important;
        display: flex;
        margin: 20px;
        width: calc(100% - 40px) !important;
        border-radius: 8px;
      }

      @media (max-width: 690px) {
        margin-top: 20px;
      }
    }

    &_form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      &_label {
        background: #fff;
        border-radius: 100px;
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
        cursor: pointer;

        &_input {
          width: 30px !important;
          height: 30px !important;
        }

        &_input::after {
          content: "";
          position: absolute;
          width: 26px;
          height: 26px;
          left: 12px;
          border-radius: 50%;

          @media (max-width: 690px) {
            width: 24px !important;
            height: 24px !important;
          }
        }

        &_input:hover::after,
        &:hover input::after {
          border: 2px solid #ff3c00 !important;
        }

        &_input:hover:checked::after {
          border: none !important;
        }

        &_input:checked::after {
          content: "\2713";
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ff3c00;
          width: 30px !important;
          height: 30px !important;
          color: #fff;
          font-size: 22px;
        }

        &_input:checked {
          background: none !important;
          color: none !important;
        }

        &_text {
          font-size: 18px;
          font-weight: 400;
          line-height: 19.8px;
          width: 140px;
        }
      }

      &_confirmLabel {
        display: flex;
        cursor: pointer;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start !important;

        input {
          margin-top: 3px;
        }

        input:hover::after {
          content: "";
          position: absolute;
          z-index: 9;
          border-radius: 2px;
          border: 2px solid #ff3c00;
          width: 9px;
          height: 9px;
        }

        input:checked::after {
          content: "\2713";
          position: absolute;
          z-index: 9;
          border: 2px solid #ff3c00;
          width: 9px;
          height: 9px;
          background: #ff3c00;
          color: #f0f0f0;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          max-width: 350px;
          font-size: 16px;
          font-weight: 300;
          line-height: 20.8px;
        }
      }

      @media (max-width: 690px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;

        &_label {
          background: none;
          border-top: 1px solid #dadada;
          border-radius: 0;

          p {
            width: 100%;
            margin: 0;
            padding: 0 !important;
          }

          input {
            width: 24px;
            height: 24px;
          }

          &_input::after {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .home_button {
      width: fit-content !important;
    }

    &_item {
      aspect-ratio: 1/1 !important;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 60px;
      color: #fff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &_tag {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.8px;
        letter-spacing: 0.05em;
        color: #000;
        border-radius: 100px;
        padding: 12px 16px;
        background: #fff;

        &s {
          display: flex;
          gap: 10px;
        }
      }

      @media (max-width: 900px) {
        width: 100% !important;
        padding: 20px;

        h3 {
          font-size: 22px;
        }

        &_text {
          font-size: 12px;
          padding: 9px 12px;
        }
      }
    }

    @media (max-width: 690px) {
      &_wrapper > &_item {
        display: none;
      }
      &_carousel {
        display: flex;
      }
      &_carousel > &_item {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &_advantages {
    display: flex;
    flex-direction: column;
    padding: 90px;
    gap: 60px;

    &_wrapper {
      display: grid;
      grid-template-columns: repeat(3, calc(100% / 3));
      gap: 40px;

      @media (max-width: 1440px) {
        grid-template-columns: repeat(2, 50%);
      }

      @media (max-width: 800px) {
        display: none;
      }
    }

    &_carousel {
      display: none;

      .home_advantages_item {
        margin-bottom: 50px;
        padding: 20px;
        display: flex;

        &_top {
          flex: 1;
        }
      }

      @media (max-width: 800px) {
        display: flex;
      }
    }

    &_item {
      display: flex;
      flex-direction: column;
      gap: 30px;

      &_top {
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media (max-width: 1440px) {
          flex-direction: row;
        }

        @media (max-width: 690px) {
          flex-direction: column;
        }
      }

      &_img {
        width: 80px;
        height: 80px;
        @media (max-width: 1300px) {
          width: 66px;
          height: 66px;
        }
      }
    }

    @media (max-width: 1440px) {
      padding: 40px;
      gap: 40px;
    }

    @media (max-width: 690px) {
      padding: 20px;
    }
  }

  &_partners {
    padding: 90px;
    display: flex;
    flex-direction: column;
    gap: 60px;

    &_wrapper {
      display: grid;
      width: calc(100% - 40px);
      grid-template-columns: repeat(3, calc(100% / 3));
      grid-template-rows: repeat(2, 1fr) !important;
      column-gap: 40px;

      img {
        width: 100%;
      }

      div {
        padding: 50px;
        border-top: 1px solid #b9b9b9;
        border-bottom: 1px solid #b9b9b9;
      }
    }

    @media (max-width: 1000px) {
      padding: 40px;
      gap: 40px;

      &_wrapper {
        grid-template-columns: repeat(2, calc(100% / 2));
      }
    }

    @media (max-width: 690px) {
      padding: 20px;
      gap: 40px;

      ::-webkit-scrollbar {
        display: none;
      }

      &_wrapper {
        display: flex;
        overflow: auto;
        width: 100%;

        div {
          padding: 20px;
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100px;
          }

          animation-name: prtnrs;
          animation-duration: 15s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }

      @keyframes prtnrs {
        from {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
      }
    }
  }

  &_faq {
    display: flex;
    flex-direction: column;
    padding: 90px;
    gap: 40px;

    &_wrapper {
      display: flex;
      flex-direction: column;

      > :last-child {
        border-bottom: 1px solid #dadada;
      }
    }

    &_item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 16px;
      border-top: 1px solid #dadada;

      &_top {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;

        button {
          color: #ff3c00;
          background: none;
          border: none;
          font-weight: 100 !important;
        }
      }

      p {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      padding: 40px;
      gap: 40px;
    }

    @media (max-width: 690px) {
      padding: 20px;
    }

    &_item {
      .home_body[opnd="true"] {
        display: flex !important;
      }
    }
  }

  &_h1 {
    font-size: 80px;
    font-weight: 500;
    line-height: 96px;
    letter-spacing: 0.03em;
    z-index: 2;

    @media (max-width: 1800px) {
      font-size: 58px;
      line-height: calc(96 / 80);
    }

    @media (max-width: 1300px) {
      font-size: 36px;
      line-height: calc(96 / 80);
    }
  }

  &_l1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 52px;
    z-index: 2;

    @media (max-width: 1800px) {
      font-size: 32px;
      line-height: calc(52 / 40);
    }

    @media (max-width: 1300px) {
      font-size: 26px;
    }
  }

  &_h2 {
    font-size: 52px;
    font-weight: 500;
    line-height: 62.4px;

    @media (max-width: 1300px) {
      font-size: 32px;
      line-height: 38px;
    }

    @media (max-width: 690px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  &_h3 {
    font-size: 36px;
    font-weight: 500;
    line-height: 43.2px;

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 30.8px;
    }

    @media (max-width: 690px) {
      font-size: 26px;
      line-height: 31.2px;
    }
  }

  &_body {
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    color: #8f8f8f;

    @media (max-width: 1300px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &_button {
    padding: 18px 25px;

    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: 0.05em;
    background: #ff3c00;
    border: none;
    border-radius: 6px;
    color: #fff;
  }
}
